import { SVGProps } from "./types";

export const GlobeFilled = (props: SVGProps): JSX.Element => (
    <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10 17C13.866 17 17 13.866 17 10C17 6.13401 13.866 3 10 3C6.13401 3 3 6.13401 3 10C3 13.866 6.13401 17 10 17ZM5.04031 8.27398C5.36724 7.3344 5.95363 6.51631 6.71368 5.90549C6.94808 6.26345 7.35268 6.49992 7.81251 6.49992C8.53739 6.49992 9.12501 7.08755 9.12501 7.81242V8.24992C9.12501 9.21642 9.90851 9.99992 10.875 9.99992C11.8415 9.99992 12.625 9.21642 12.625 8.24992C12.625 7.42732 13.1926 6.73728 13.9575 6.55004C14.7624 7.47266 15.25 8.67936 15.25 9.99993C15.25 10.2981 15.2252 10.5905 15.1774 10.8751H14.375C13.4085 10.8751 12.625 11.6586 12.625 12.6251V14.5476C11.8528 14.9943 10.9562 15.2499 9.99993 15.2499V13.5C9.99993 12.5335 9.21642 11.75 8.24993 11.75C7.28343 11.75 6.49993 10.9665 6.49993 10C6.49993 9.13243 5.86861 8.41231 5.04031 8.27398Z"
            fill="currentColor"
        />
    </svg>
);

export const GlobeUnfilled = (props: SVGProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        fill="none"
        viewBox="0 0 20 20"
        {...props}
    >
        <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.67"
            d="M17 10a7 7 0 0 1-7 7m7-7a7 7 0 0 0-7-7m7 7H3m7 7a7 7 0 0 1-7-7m7 7c1.29 0 2.33-3.13 2.33-7S11.3 3 10 3m0 14c-1.29 0-2.33-3.13-2.33-7S8.7 3 10 3m-7 7a7 7 0 0 1 7-7"
        />
    </svg>
);
