import NextImage, { ImageProps } from "next/image";

let hasWebpSupport: boolean | null = null;
if (typeof window !== "undefined") {
    // Source: https://developers.google.com/speed/webp/faq#in_your_own_javascript
    const checkWebpSupport = (feature: string): Promise<[string, boolean]> => {
        return new Promise((res) => {
            const kTestImages = {
                lossy: "UklGRiIAAABXRUJQVlA4IBYAAAAwAQCdASoBAAEADsD+JaQAA3AAAAAA",
                lossless: "UklGRhoAAABXRUJQVlA4TA0AAAAvAAAAEAcQERGIiP4HAA==",
                alpha: "UklGRkoAAABXRUJQVlA4WAoAAAAQAAAAAAAAAAAAQUxQSAwAAAARBxAR/Q9ERP8DAABWUDggGAAAABQBAJ0BKgEAAQAAAP4AAA3AAP7mtQAAAA==",
                animation:
                    "UklGRlIAAABXRUJQVlA4WAoAAAASAAAAAAAAAAAAQU5JTQYAAAD/////AABBTk1GJgAAAAAAAAAAAAAAAAAAAGQAAABWUDhMDQAAAC8AAAAQBxAREYiI/gcA",
            };
            const img = new Image();
            img.onload = () => {
                const result = img.width > 0 && img.height > 0;
                res([feature, result]);
            };
            img.onerror = () => {
                res([feature, false]);
            };
            img.src = `data:image/webp;base64,${kTestImages[feature]}`;
        });
    };
    if (hasWebpSupport === null) {
        checkWebpSupport("lossy").then(
            ([webpFeature, isWebpFeatureSupported]) => {
                hasWebpSupport =
                    webpFeature === "lossy" && isWebpFeatureSupported;
            },
        );
    }
}

const encodeURL = (url: string): string => {
    return Buffer.from(url)
        .toString("base64")
        .replace(/=/g, "")
        .replace(/\+/g, "-")
        .replace(/\//g, "_");
};

export const imgproxyLoader = ({ src, width }): string => {
    const imageURL = new URL(src);

    let normalisedExtension = "";
    if (hasWebpSupport) {
        normalisedExtension = ".webp";
    } else if (imageURL.pathname.endsWith(".webp")) {
        normalisedExtension = ".jpg";
    }

    const encodedURL = encodeURL(imageURL.toString());
    const proxyURL = new URL(
        `/insecure/rs:auto:${width}:0:0/g:sm/${encodedURL}${normalisedExtension}`,
        process.env.NEXT_PUBLIC_ENJIN_IMGPROXY_URL,
    );
    return proxyURL.toString();
};

const ImgProxyImage = ({
    src,
    unoptimized,
    ...otherProps
}: ImageProps & { src: string }): JSX.Element => {
    const props = {
        src,
        loader: imgproxyLoader,
        unoptimized,
    };

    const isAbsoluteImage =
        src.startsWith("http://") || src.startsWith("https://");
    if (!isAbsoluteImage) {
        if (process.env.NEXT_PUBLIC_ENJIN_PELANGGAN_SITE_URL) {
            const absoluteImageURL = new URL(
                src,
                process.env.NEXT_PUBLIC_ENJIN_PELANGGAN_SITE_URL,
            );
            props.src = absoluteImageURL.toString();
        } else {
            props.unoptimized = true;
        }
    }

    return <NextImage {...otherProps} {...props} />;
};

export { ImgProxyImage as Image };
