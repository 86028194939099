import { SVGProps } from "./types";

export const CalendarUnfilled = (props: SVGProps): JSX.Element => (
    <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M6.88889 6.11111V3M13.1111 6.11111V3M6.11111 9.22222H13.8889M4.55556 17H15.4444C16.3036 17 17 16.3036 17 15.4444V6.11111C17 5.252 16.3036 4.55556 15.4444 4.55556H4.55556C3.69645 4.55556 3 5.252 3 6.11111V15.4444C3 16.3036 3.69645 17 4.55556 17Z"
            stroke="currentColor"
            strokeWidth="1.67"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export const CalendarFilled = (props: SVGProps): JSX.Element => (
    <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.5 3C6.01675 3 5.625 3.39175 5.625 3.875V4.75H4.75C3.7835 4.75 3 5.5335 3 6.5V15.25C3 16.2165 3.7835 17 4.75 17H15.25C16.2165 17 17 16.2165 17 15.25V6.5C17 5.5335 16.2165 4.75 15.25 4.75H14.375V3.875C14.375 3.39175 13.9832 3 13.5 3C13.0168 3 12.625 3.39175 12.625 3.875V4.75H7.375V3.875C7.375 3.39175 6.98325 3 6.5 3ZM6.5 7.375C6.01675 7.375 5.625 7.76675 5.625 8.25C5.625 8.73325 6.01675 9.125 6.5 9.125H13.5C13.9832 9.125 14.375 8.73325 14.375 8.25C14.375 7.76675 13.9832 7.375 13.5 7.375H6.5Z"
            fill="currentColor"
        />
    </svg>
);
