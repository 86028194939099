import { SVGProps } from "./types";

export const StarFilled = (props: SVGProps): JSX.Element => (
    <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M9.10456 3.94668C9.38641 3.07925 10.6136 3.07925 10.8954 3.94669L11.9025 7.04597C12.0285 7.4339 12.39 7.69655 12.7979 7.69655H16.0567C16.9687 7.69655 17.348 8.86367 16.6101 9.39977L13.9737 11.3152C13.6437 11.555 13.5056 11.98 13.6317 12.3679L14.6387 15.4672C14.9205 16.3346 13.9277 17.0559 13.1898 16.5198L10.5534 14.6044C10.2234 14.3646 9.77658 14.3646 9.44659 14.6044L6.81018 16.5198C6.0723 17.0559 5.07949 16.3346 5.36133 15.4672L6.36835 12.3679C6.4944 11.98 6.35631 11.555 6.02632 11.3152L3.38991 9.39977C2.65203 8.86367 3.03125 7.69655 3.94332 7.69655H7.20211C7.61 7.69655 7.9715 7.4339 8.09754 7.04597L9.10456 3.94668Z"
            fill="currentColor"
        />
    </svg>
);
