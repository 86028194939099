import { SVGProps } from "./types";

export const PencilAltUnfilled = (props: SVGProps): JSX.Element => (
    <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M8.7647 4.64706H4.64706C3.73741 4.64706 3 5.38447 3 6.29412V15.3529C3 16.2626 3.73741 17 4.64706 17H13.7059C14.6155 17 15.3529 16.2626 15.3529 15.3529V11.2353M14.1883 3.48241C14.8315 2.8392 15.8744 2.8392 16.5176 3.48241C17.1608 4.12563 17.1608 5.16849 16.5176 5.8117L9.44694 12.8824H7.11765L7.11765 10.5531L14.1883 3.48241Z"
            stroke="currentColor"
            strokeWidth="1.67"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export const PencilFilled = (props: SVGProps): JSX.Element => (
    <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M13.5858 3.58579C14.3668 2.80474 15.6332 2.80474 16.4142 3.58579C17.1953 4.36683 17.1953 5.63316 16.4142 6.41421L15.6213 7.20711L12.7929 4.37868L13.5858 3.58579Z"
            fill="currentColor"
        />
        <path
            d="M11.3787 5.79289L3 14.1716V17H5.82842L14.2071 8.62132L11.3787 5.79289Z"
            fill="currentColor"
        />
    </svg>
);
